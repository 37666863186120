.login-navbar {
  position: fixed;
  height: 100%;
  width: 0;
  z-index: 1;
  background-color: rgb(27, 27, 27);
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.nav-title {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-bottom: solid 2px white;
}

.navbar-links {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.redirect {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
}

.redirect:hover {
  cursor: pointer;
  background-color: rgb(41, 41, 41);
}
