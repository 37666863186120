.menu-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: all 0, 5s ease-in-out;
}

.menu-btn__burger {
  width: 50px;
  height: 5px;
  background-color: black;
  transition: all 0.5s ease-in-out;
  border-radius: 5px;
}

.menu-btn__burger::before,
.menu-btn__burger::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 5px;
  background-color: black;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.menu-btn__burger::before {
  transform: translateY(-16px);
}

.menu-btn__burger::after {
  transform: translateY(16px);
}

/*animation*/
.menu-btn.open .menu-btn__burger {
  transform: translateX(-20px);
  background: transparent;
}

.menu-btn.open .menu-btn__burger::before {
  transform: rotate(45deg) translate(15px, -15px);
}

.menu-btn.open .menu-btn__burger::after {
  transform: rotate(-45deg) translate(15px, 15px);
}
