.LoadingAnimation {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

@keyframes loading {
  50% {
    transform: translateY(-10px);
  }
}

.loading-dot-one {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: black;
  animation-name: loading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.loading-dot-two {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: black;
  animation-name: loading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.2s;
}

.loading-dot-three {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: black;
  animation-name: loading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.4s;
}
