.Login {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  background-image: url("../assets/Landscape-with-blue-and-purple-silhouettes-of-mountains-and-hills-18612.jpg");
  background-size: 100% 100%;
}

.navbar-top {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 5%;
  margin-left: 5%;
}

.login-navbar-section {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 2;
  width: 100%;
  height: 100%;
}

.login-navbar-container {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  width: 100%;
  height: 100%;
  display: none;
}

.login-form-container {
  background-color: white;
  width: 100%;
  height: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  grid-column-start: 2;
  grid-column-end: 12;
  grid-row-start: 3;
  grid-row-end: 10;
  align-items: center;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}

.login-form-title {
  width: 100%;
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
}

#loginFormId {
  width: 100%;
  height: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-email-container {
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#loginEmailId {
  width: 80%;
  height: 50%;
  display: flex;
  background-color: transparent;
  border: none;
  border-bottom: solid 2px lightgray;
}

.login-password-container {
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#loginPasswordId {
  width: 80%;
  height: 50%;
  display: flex;
  background-color: transparent;
  border: none;
  border-bottom: solid 2px lightgray;
}

.forgot-password {
  display: none;
}

.login-submit-container {
  width: 100%;
  height: 33.33%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-submit-button {
  text-decoration: none;
  color: black;
  background-color: rgb(141, 135, 252);
  width: 30%;
  height: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  transition: all 0.2s ease-in-out;
  border: none;
}

.login-submit-button:hover {
  cursor: pointer;
  color: white;
  transition: all 0.2s ease-in-out;
  background-image: linear-gradient(
    to left,
    rgb(141, 135, 252),
    rgb(97, 159, 253)
  );
}

.errorAlert {
  position: fixed;
  width: 90%;
  height: 1000px;
  background-color: rgb(238, 131, 81);
}

@media screen and (min-width: 769px) {
  .login-navbar-section {
    display: none;
  }

  .login-navbar-container {
    display: flex;
    justify-content: space-between;
  }

  .login-navbar-title {
    /*width: 30%;*/
    width: max-content;
    margin-left: 5%;
    height: 100%;
    display: flex;
    justify-content: center;
    font-size: 2em;
  }

  .login-navbar-links {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .login-link-styling {
    text-decoration: none;
    color: black;
    background-color: rgb(141, 135, 252);
    width: 30%;
    height: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    transition: all 0.2s ease-in-out;
  }

  .login-link-styling:hover {
    cursor: pointer;
    color: white;
    transition: all 0.2s ease-in-out;
    background-image: linear-gradient(
      to left,
      rgb(141, 135, 252),
      rgb(97, 159, 253)
    );
  }
}
