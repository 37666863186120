.User {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  background-image: url("../assets/Landscape-with-blue-and-purple-silhouettes-of-mountains-and-hills-18612.jpg");
  background-size: cover;
}

.navbar-user {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 2;
}

.sliding-navbar-user {
  grid-row-start: 1;
  grid-row-end: 13;
  grid-column-start: 1;
  grid-column-end: 13;
}

.user-data-section {
  width: 100%;
  height: 100%;
  grid-row-start: 2;
  grid-row-end: 13;
  grid-column-start: 1;
  grid-column-end: 13;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.newUserPost {
  width: 80%;
  height: 250px;
  outline: solid 2px black;
  display: flex;
  margin: 10px;
  border-radius: 10px;
  background-color: rgb(190, 177, 228);
  transition: all 0.5s ease-out;
}

.newImgContainer {
  width: 50%;
  height: 100%;
  border-radius: 10px;
  border-top-right-radius: 0px;
}

.newImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.newUserPostContent {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.newUserPostTop {
  width: 100%;
  height: 20%;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-right-radius: 10px;
  background-color: rgb(190, 177, 228);
  border-bottom: solid 1px black;
  overflow-x: auto;
  overflow-y: hidden;
}

.newTitle {
  margin-left: 10px;
  font-size: large;
  font-weight: 500;
}

.newDate {
  margin-right: 10px;
}

.newText {
  height: 60%;
  width: 95%;
  overflow-y: auto;
  display: flex;
  margin-left: 5%;
  margin-bottom: 0;
}

.deletePost {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 20%;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.deleteSymbol {
  width: 1%;
  height: 100%;
  background-color: gray;
  opacity: 0.9;
  transform: rotate(-45deg);
  border-radius: 5px;
}

.deleteSymbol:before {
  content: "";
  transform: rotate(90deg);
  background-color: gray;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.confirmText {
  color: black;
}

.confirmYes {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirmNo {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.yesCheck {
  width: 10%;
  height: 100%;
}

.yesCheck::before {
  content: "";
  display: inline-block;
  width: 0.3em;
  height: 0.8em;
  background-color: beige;
  transform: rotate(-45deg) translateX(-340%) translateY(45%);
  margin: 0;
}

.yesCheck::after {
  content: "";
  display: inline-block;
  width: 0.3em;
  height: 2em;
  background-color: beige;
  transform: rotate(45deg) translateX(-100%) translateY(-50%);
  margin: 0;
}

.noCheck {
  width: 10%;
  height: 100%;
}

.noCheck::before {
  content: "";
  display: inline-block;
  background-color: gray;
  opacity: 0.9;
  width: 0.3em;
  height: 2em;
  transform: rotate(-45deg) translateY(5px) translateX(-5px);
}

.noCheck::after {
  content: "";
  display: inline-block;
  background-color: gray;
  opacity: 0.9;
  width: 0.3em;
  height: 2em;
  transform: rotate(45deg) translateY(-20px) translateX(-21px);
}

.removePost {
  transition: all 0.5s ease-in-out;
  width: 0px;
  height: 0px;
  border-radius: 0px;
}

.loading-animation-container {
  width: 100%;
  height: 100%;
  display: none;
}

.navbar-large-device {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  display: none;
}

@media screen and (max-width: 500px) {
  .newUserPost {
    min-height: 400px;
    flex-direction: column;
  }
  .newImgContainer {
    width: 100%;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 10px;
  }

  .newImg {
    object-fit: fill;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 10px;
  }

  .newUserPostContent {
    width: 100%;
    overflow-y: auto;
  }
}

@media screen and (min-width: 1024px) {
  .newUserPost {
    max-width: 900px;
  }
  .navbar-user {
    display: none;
  }

  .navbar-large-device {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
  }

  .navbar-large-device-title {
    margin-left: 5%;
  }

  .navbar-user-link-container {
    margin-right: 5%;
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .user-links {
    text-decoration: none;
    color: black;
    background-color: rgb(141, 135, 252);
    width: 30%;
    height: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    transition: all 0.2s ease-in-out;
  }

  .user-links:hover {
    cursor: pointer;
    color: white;
    transition: all 0.2s ease-in-out;
    background-image: linear-gradient(
      to left,
      rgb(141, 135, 252),
      rgb(97, 159, 253)
    );
  }
}
