.Home {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 7.5% repeat(11, 1fr);
  background-image: url("../assets/Landscape-with-blue-and-purple-silhouettes-of-mountains-and-hills-18612.jpg");
  background-size: 100% 100%;
}

.navbar {
  width: 100%;
  height: 100%;
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-title-container {
  width: 20%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.links {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
}

.register-link {
  width: 30%;
  height: 45%;
  border-radius: 16px;
  background-color: rgb(141, 135, 252);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: black;
}

.register-link:hover {
  cursor: pointer;
  color: white;
  transition: all 0.2s ease-in-out;
  background-image: linear-gradient(
    to left,
    rgb(141, 135, 252),
    rgb(97, 159, 253)
  );
}

.logout-link {
  width: 30%;
  height: 45%;
  border-radius: 16px;
  background-color: rgb(141, 135, 252);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: black;
}

.logout-link:hover {
  cursor: pointer;
  color: white;
  transition: all 0.2s ease-in-out;
  background-image: linear-gradient(
    to left,
    rgb(141, 135, 252),
    rgb(97, 159, 253)
  );
}

.login-link {
  width: 30%;
  height: 45%;
  border-radius: 16px;
  background-color: rgb(141, 135, 252);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: black;
}

.login-link:hover {
  cursor: pointer;
  color: white;
  transition: all 0.2s ease-in-out;
  background-image: linear-gradient(
    to left,
    rgb(141, 135, 252),
    rgb(97, 159, 253)
  );
}

.userprofile-link {
  width: 30%;
  height: 45%;
  border-radius: 16px;
  background-color: rgb(141, 135, 252);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: black;
}

.userprofile-link:hover {
  cursor: pointer;
  color: white;
  transition: all 0.2s ease-in-out;
  background-image: linear-gradient(
    to left,
    rgb(141, 135, 252),
    rgb(97, 159, 253)
  );
}

.main-title {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 2;
  grid-row-end: 4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-box {
  width: 100%;
  height: 100%;
  grid-column-start: 3;
  grid-column-end: 11;
  grid-row-start: 4;
  grid-row-end: 9;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: none;
  overflow-y: auto;
}

.text-conversion-box {
  width: 98%;
  height: 95%;
  background-color: lightgray;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: dashed 2px black;
  overflow-x: hidden;
  overflow-y: auto;
}

.file-upload {
  display: none;
}

.file-upload-label {
  width: 15%;
  height: 15%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(141, 135, 252);
  transition: all 0.2s ease-in-out;
}

.file-upload-label:hover {
  cursor: pointer;
  background-image: linear-gradient(
    to left,
    rgb(141, 135, 252),
    rgb(97, 159, 253)
  );
  color: white;
  transition: all 0.2s ease-in-out;
}

.save-data {
  width: 100%;
  height: 100%;
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 9;
  grid-row-end: 10;
  display: none;
  align-items: center;
  justify-content: center;
}

.save-data-button {
  width: 100px;
  height: 70%;
  background-color: rgb(141, 135, 252);
  border: none;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  margin-right: 1%;
}

.save-data-button:hover {
  cursor: pointer;
  color: white;
  background-image: linear-gradient(
    to left,
    rgb(141, 135, 252),
    rgb(97, 159, 253)
  );
  transition: all 0.2s ease-in-out;
}

.loading-container {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: none;
}

.converted-text-container {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  word-wrap: break-word;
  display: none;
  align-items: center;
}

.converted-text {
  width: 100%;
  height: max-content;
  max-height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.new-upload-button {
  width: 100px;
  height: 70%;
  background-color: rgb(141, 135, 252);
  border: none;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  margin-left: 1%;
}

.new-upload-button:hover {
  cursor: pointer;
  color: white;
  background-image: linear-gradient(
    to left,
    rgb(141, 135, 252),
    rgb(97, 159, 253)
  );
  transition: all 0.2s ease-in-out;
}

.post-title {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: none;
}

.post-title-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 10%;
  margin: auto;
  height: 30%;
  width: 65%;
  max-width: 500px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.3);
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.post-title-label {
  width: 100%;
  height: 10%;
  text-align: center;
  justify-content: center;
  display: flex;
  margin: 0;
  margin-top: 20px;
  font-size: 1em;
}

.save-title-form {
  display: flex;
  flex-direction: column;
  color: red;
  width: 80%;
  height: 80%;
  justify-content: space-evenly;
  align-items: center;
}

.save-title-input {
  width: 80%;
  height: 10%;
  border: none;
  border-bottom: solid 2px lightgray;
}

.save-title-button-container {
  display: flex;
  width: 100%;
  height: 20%;
  justify-content: space-evenly;
}

.save-title-button {
  width: 45%;
  height: 100%;
  background-color: rgb(141, 135, 252);
  border: none;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  margin-right: 1%;
}

.save-title-button:hover {
  cursor: pointer;
  color: white;
  background-image: linear-gradient(
    to left,
    rgb(141, 135, 252),
    rgb(97, 159, 253)
  );
  transition: all 0.2s ease-in-out;
}

.cancel-title-button {
  width: 45%;
  height: 100%;
  background-color: rgb(141, 135, 252);
  border: none;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  margin-right: 1%;
}

.cancel-title-button:hover {
  cursor: pointer;
  color: white;
  background-image: linear-gradient(
    to left,
    rgb(141, 135, 252),
    rgb(97, 159, 253)
  );
  transition: all 0.2s ease-in-out;
}

.newUserButton {
  width: 100px;
  height: 70%;
  background-color: rgb(141, 135, 252);
  border: none;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  margin-left: 1%;
}

.newUserButton:hover {
  cursor: pointer;
  color: white;
  background-image: linear-gradient(
    to left,
    rgb(141, 135, 252),
    rgb(97, 159, 253)
  );
  transition: all 0.2s ease-in-out;
}

@keyframes loading {
  50% {
    color: red;
  }
}
.loading-animation-display {
  width: 100px;
  height: 50px;
}

@media screen and (max-width: 766px) {
  .page-title-container {
    width: max-content;
    height: max-content;
    font-size: 1rem;
    margin-left: 20px;
  }
  .links {
    display: none;
  }
  .hamburger-styling {
    display: flex;
    width: max-content;
    margin-right: 20px;
    height: 100%;
  }
  .main-title {
    font-size: 1rem;
  }
  .main-box {
    grid-column-start: 2;
    grid-column-end: 12;
    width: 100%;
    height: 100%;
  }
  .file-upload-label {
    width: max-content;
    font-size: 0.85em;
    padding-left: 2%;
    padding-right: 2%;
  }
}

@media screen and (min-width: 766px) and (max-width: 1023px) {
  .page-title-container {
    width: max-content;
    margin-left: 5%;
  }

  .hamburger-styling {
    margin-right: 5%;
  }

  .links {
    display: none;
  }

  .file-upload-label {
    width: max-content;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media screen and (min-width: 1024px) {
  .page-title-container {
    width: max-content;
    margin-left: 5%;
  }
  .hamburger-styling {
    display: none;
  }

  .file-upload-label {
    width: max-content;
    padding-left: 2%;
    padding-right: 2%;
  }

  .save-data-button {
    width: 10%;
    font-size: 1em;
  }

  .new-upload-button {
    width: 10%;
    font-size: 1em;
  }

  .newUserButton {
    width: 10%;
    font-size: 1em;
  }
}
